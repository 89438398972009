.container {
    padding: 0 48px;
    position: relative;

    &.mobile {
        padding: 0;
    }
}

.box {
    display: flex;
    gap: var(--small-carousel-box-gap, 24px);
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 16px;
    scroll-snap-type: mandatory;
    scroll-snap-points-x: repeat(100px);
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    > * {
        scroll-snap-align: start;
    }
}

.arrow {
    color: var(--text-color-wt);
    width: 28px;
    height: 28px;
    background-color: #FFEECD;
    border: 1px solid rgb(var(--primary-main));
    border-radius: 8px;
    position: absolute;
    top: calc(50% - 8px);
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.prev {
        left: 8px;
    }

    &.next {
        right: 8px;
    }

    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}
