.score {
    line-height: 2;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgb(var(--primary-main));

    &.disabled {
        background-color: rgb(var(--gray-dr));
        border-color: rgb(var(--gray-dr));
    }

    &:empty {
        visibility: hidden;
    }

    + .score {
        margin-left: 0.75rem;
    }
}