.title {
    padding: 0 0;
    font-family: var(--secondary-font);

    &.title {
        @media (max-width: 1040px) {
            margin-bottom: 32px; 
        }
    }

    &.lines {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        &:before, &:after {
            content: "";
            width: 100%;
            max-width: 240px;
            height: 2px;
            background-color: rgb(var(--primary-main));
        }
    
        &:before {
            margin-right: 1rem;
        }
    
        &:after {
            margin-left: 1rem;
        }
    }

    @media (max-width: 1040px) {
        &:not(.force) {
            display: none;
        }
    }
}
