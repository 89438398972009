.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    background-color: rgba(var(--secondary-dark), 0.6);
    outline: none;
    -webkit-tap-highlight-color: transparent;

    @media (max-width: 480px) {
        align-items: flex-end;
    }
}

.box {
    position: relative;
    padding: 2.25rem;
    border-radius: 0.25rem;
    outline: none;
    cursor: default;
    background-color: var(--main-bg-color);
    overflow-y: auto;
    min-width: 20rem;
    max-height: 100vh;
    -webkit-tap-highlight-color: transparent;
    animation: zoomIn 0.2s forwards;
    overscroll-behavior: contain;

    @media (max-width: 480px) {
        width: 100%;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        max-height: calc(100vh - 5.5rem);
        animation: fadeIn 0.25s forwards;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    fill: rgb(var(--red-color));
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
        fill: rgba(var(--red-color), 0.7);
    }

    @media (max-width: 480px) {
        top: 0.25rem;
    }
}

.title {
    @media (max-width: 480px) {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}

@keyframes zoomIn {
    0% {
        opacity:0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    100% { 
        opacity: 1; 
    }
}

@keyframes fadeIn {
    0% {
       opacity: 0;
    }
    100% { 
        opacity: 1;
    }
}
