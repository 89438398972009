.container {
    flex-direction: column;

    &:focus {
        outline: none;
    }

    @media (max-width: 480px) {
        padding: 1.5rem 0.25rem 0;
    }
}

.box {
    max-width: 540px;
    width: 100%;
}

.input {
    width: 100%;

    &::placeholder {
        color: var(--gray-sdr);
    }

    &.disabled {
        border-color: rgb(var(--gray-dr));

        &::placeholder {
            color: rgb(var(--gray-dr));
        }
    }

    &.inputSuc {
        border-color: rgb(var(--green-color)); 
    }
}

.icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    pointer-events: none;

    svg {
        fill: rgb(var(--red-color)); 
    }

    &.iconSuc {
        svg {
            fill: rgb(var(--green-color)); 
        } 
    }
}

.suggestions {
    overflow: hidden;
    max-height: 0;
    color: rgb(var(--primary-light));
    line-height: 2;
    transition: max-height 0.2s;

    &.open {
        max-height: 60px;
        border-bottom: 1px solid var(--white);
    }
}

.block {
    padding: 3rem 2.25rem 2.25rem;
    text-align: center;

    @media (max-width: 480px) {
        padding: 2.25rem 1.5rem;
    }
}

.googleButton {
    display: inline-flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--white);
    color: var(--text-color-wt);

    --global-button-hover-bg: rgba(var(--primary-dark), 0.1);
    --global-button-active-bg: rgba(255, 255, 255, 0.8);

    &.hidden {
        display: none;
    }
}

.divider {
    height: 1px;
    margin: 32px 0 48px;
    padding: 0;
    overflow: visible;
    border: none;
    background-color: rgba(255, 255, 255, 0.4);
    color: rgba(255, 255, 255, 0.6);
    text-align: center;

    &::after {
        content: "or sign in with email";
        display: inline-block;
        padding: 0 16px;
        background: rgb(var(--secondary-light));
        font-size: 14px;
        transform: translateY(-60%);
    }
}
