.time {
    padding-bottom: 0.25rem;
    border-bottom: 1px solid rgb(var(--gray-dr));
    border-style: dashed;
}

.poolItem {
    width: 100%;
    padding: 0 1rem;
    cursor: pointer;
    background-color: rgba(var(--secondary-light), 1);
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    background-repeat: no-repeat;

    &:focus {
        box-shadow: 0 0 0 4px rgba(var(--primary-dark), 0.3);
    }

    @media (max-width: 480px) {
        width: 100%;
    }

    &.euroweek {
        background-image: url("/poolLogos/EUROWEEK.png");
        background-size: 90%;
        background-position: center;
    }

    &.bundesliga {
        background-image: url("/poolLogos/bundesliga.png");
        background-size: 90%;
        background-position: center 40%;
    }

    &.championsleague {
        background-image: url("/poolLogos/champions-league.png");
        background-size: 90%;
        background-repeat: no-repeat;
        background-position: center 40%;
    }

    &.championship {
        background-image: url("/poolLogos/championship.png");
        background-size: 80%;
        background-position: center 40%;
    }

    &.conferenceleague {
        background-image: url("/poolLogos/conference-league.png");
        background-size: 70%;
        background-position: center 40%;
    }

    &.englishpremierleague {
        background-image: url("/poolLogos/english-premier-league.png");
        background-size: 80%;
        background-position: center 40%;
    }

    &.euro {
        background-image: url("/poolLogos/euro-2024.png");
        background-size: 80%;
        background-position: center 40%;
    }

    &.eurocups {
        background-image: url("/poolLogos/EUROCUPS.png");
        background-size: 80%;
        background-position: center 40%;
    }

    &.europaleague {
        background-image: url("/poolLogos/europa-league.png");
        background-size: 80%;
        background-position: center 40%;
    }

    &.franceligue1 {
        background-image: url("/poolLogos/france-ligue-1.png");
        background-size: 80%;
        background-position: center 40%;
    }

    &.laliga {
        background-image: url("/poolLogos/laliga.png");
        background-size: 80%;
        background-position: center 40%;
    }

    &.mlsusa {
        background-image: url("/poolLogos/MLS-USA.png");
        background-size: 80%;
        background-position: center 40%;
    }

    &.roshnsaudi {
        background-image: url("/poolLogos/roshn-saudi.png");
        background-size: 80%;
        background-position: center 40%;
    }

    &.seriaa {
        background-image: url("/poolLogos/seria-a.png");
        background-size: 80%;
        background-position: center;
    }

    &.uefa {
        background-image: url("/poolLogos/uefa.png");
        background-size: 80%;
        background-position: center;
    }
}

.poolTitle {
    font-weight: bold;
    font-size: 24px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: top center;

    &.f {
        background-image: url("/bets/sport-1.svg");
        background-size: 2rem;
    }

    &.b {
        background-image: url("/bets/sport-2.svg");
        background-size: 1.125rem;
    }

    &.t {
        background-image: url("/bets/sport-3.svg");
        background-size: 1.125rem;
    }
}

.poolFormats {
    flex-wrap: wrap;
    gap: 0.5rem;
}

.poolFormat {
    min-width: 5.5rem;
    background-color: rgba(var(--secondary-dark), 0.4);
    box-shadow: none;
}

.poolView {
    cursor: pointer;
    padding-left: 0.5rem;

    svg {
        fill: rgba(var(--primary-light), 1);
    }
}

.hidden {
    display: none;
}

.lastItem {
    margin-top: auto;
    font-size: 11px;
}

.buton {
    height: 32px;
    padding: 0 24px;
    border-radius: 32px;
    line-height: 2;
    background-color: rgb(var(--primary-main));
    white-space: nowrap;
}

.systemInfo {
    font-size: 12px;
    padding-top: 8px;
}

.toPrivate {
    margin-left: 10%;
    margin-top: 16px;
    background: rgba(var(--secondary-light), 1) url("/poolLogos/friends-link.png") no-repeat;
    border-top: 1px solid rgba(var(--secondary-main), 1);
    background-position: center;
    background-size: contain;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    border-radius: 0.5rem;
    padding: 1.25rem 1rem 0.5rem 1rem;

    @media (max-width: 480px) {
        margin-left: 2%;
        width: 96%;

        a {
            width: 100%;
        }
    }

    .prInfo {
        font-size: 11px;
    }

    .privateButton {
        background-color: var(--main-bg-color);
        color: rgb(var(--primary-main));
    }
}

.orBlock {
    position: absolute;
    bottom: 100%;
    left: 50%;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: bold;
    background: rgba(var(--primary-main), 0.8);
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    pointer-events: none;
    clip-path: polygon(100% 0, 100% 60%, 49% 90%, 0 60%, 0 0, 50% 30%);
    color: rgba(30 25 39 / 90%);
    transform: translate(-50%, 25%);

    svg {
       width: 1.5rem;
       height: 1.5rem;
       fill: rgba(30 25 39 / 80%);
    }
}
