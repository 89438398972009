.blur {
    filter: blur(5px);
}

.popUp {
    margin-top: 1.5rem;

    @media (max-width: 480px) {
        padding: 0;
    }
}