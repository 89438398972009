.loader {
    position: relative;
    overflow: hidden;

    > *:not(.spinner) {
        filter: blur(3px);
    }
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 40;
    height: 100%;
    width: 100%;
}

.item {
    width: 1rem;
    height: 1rem;
  
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1s infinite ease-in-out both;
}

.bounce1  {
    background-color: rgb(var(--primary-light));
    animation-delay: -0.3s;
}

.bounce2 {
    background-color: rgb(var(--secondary-dark));
    animation-delay: -0.15s;
}

.bounce3 {
    background-color: rgb(var(--secondary-light));
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        transform: scale(0.7);
        opacity: 0.5;
    } 20% {
        transform: scale(0.5);
    } 40% {
        transform: scale(1);
        opacity: 1;
    }
}