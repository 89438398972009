.section {
    margin-top: 3rem;

    &.canceled {
        opacity: 0.5;
    }

    @media (max-width: 480px) {
        margin-top: 0;
        margin-bottom: 2.5rem;

        header {
            margin-bottom: 0.5rem
        }
    }

    .scoreRow {
        line-height: 1.875rem;
        padding-top: 0;

        .scoreItem {
            min-width: 10.5rem;
            justify-content: center;
            border: 1px solid rgb(var(--primary-main));
            background-color: rgb(var(--secondary-main));
        }

        @media (max-width: 480px) {
            padding-bottom: 0;

            &:empty {
                padding-bottom: 0.5rem;
            }

            .scoreItem {
                margin-bottom: 0.5rem;
                margin-left: 0;
                margin-right: 0.75rem;
            }
        }

        .icon {
            cursor: pointer;
            fill: rgb(var(--green-color));
        }
    
        .close {
            cursor: pointer;
            fill: rgb(var(--red-color));
        }
    }

    .scoreInner {
        min-height: 64px;
        display: flex;
        align-items: center;
        padding-top: 8px;
    }

    .cellCenter {
        padding-left: 0;
        padding-right: 0;
    }
}

.container.container {
    max-width: 100%;
    overflow-x: auto;
    background-color: rgb(var(--secondary-main));

    .fixedCell {
        width: 40%;
    }
}

.tableHeader {
    background-color: rgb(var(--secondary-dark));
    border-bottom: 1px solid rgb(var(--secondary-light));

    @media (max-width: 480px) {
        font-size: 14px;
        line-height: 1;
    }
}

.headerInner {
    gap: 0.5rem;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        @media (max-width: 480px) {
            display: none;
        }
    }
}

.time {
    opacity: 0.7;
}

.subRow {
    background-color: rgba(var(--secondary-light), 1);   
}

.subTitle {
    opacity: 0.8;
}

.scoreLine {
    white-space: nowrap;
    font-size: 0;

    &:nth-child(3) .scoreCell {
         height: 3.75rem;
         line-height: 1.875rem;
         width: 10.125rem;

        @media (max-width: 480px) {
            width: 7.25rem;
            line-height: 1.25rem;
            height: 4rem;
            font-size: 12px;
        }
    }
}

.mainRow td {
    @media (max-width: 480px) {
        &:first-child {
            padding-left: 0;
            padding-right: 0;
        }

        &:last-child {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.scoreCell {
    display: inline-block;
    text-align: center;
    overflow: hidden;
    width: 5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    background-color: rgba(var(--secondary-light), 1);
    margin: 4px;
    border-radius: 4px;
    cursor: pointer;

    svg {
        fill: var(--white);
    }

    @media (max-width: 480px) {
        width: 2.25rem;
        line-height: 2.2;
        height: 3.6rem;
        margin: 2px;
        font-size: 16px;
        font-weight: bold;
        background-color: transparent;

        > span {
            display: block;
            border-radius: 4px;
            background-color: rgba(var(--secondary-light), 1);
        }
    }

    @media (min-width: 481px) {
        > span > span {
            display: block;
        }
    }

    &:hover {
        background-color: rgba(var(--primary-main), 0.4);

       @media (max-width: 480px) {
        background-color: transparent;

            > span {
                background-color: rgba(var(--primary-main), 0.4);
            }
        }
    }

    &.big {
        width: 10.125rem;
        pointer-events: none;
        background-color: rgba(var(--gray-dr), 0.1);

        strong {
            padding-left: 0.25rem;
        }

        .checkIcon {
            margin-bottom: 4px;
            width: 0;
            fill: var(--white);
        }

        &.active {
            background-color: rgba(var(--gray-dr), 0.3);

            .checkIcon {
                width: auto;
            }
        }

        @media (max-width: 480px) {
            display: inline-flex;
            flex-direction: column;
            width: 6.75rem;
            height: 2.85rem;
            line-height: 1.5rem;
            background-color: transparent;

            > span {
                background-color: rgba(var(--gray-dr), 0.1);

                &:nth-child(2n) {
                    font-size: 10px;
                    line-height: 1.6;
                    margin-top: 2px;
                }
            }

            &.active {
                background-color: transparent;
    
                > span {
                    background-color: rgba(var(--gray-dr), 0.3);
                }    
            }

            strong {
                display: none;
            }
        }
    }

    &.active {
        background-color: rgb(var(--primary-main));

        @media (max-width: 480px) {
            background-color: transparent;

            > span {
                background-color: rgb(var(--primary-main));
            }
        }
    }

    .bigScore {
        line-height: 1.4;

        > span {
            display: block;
        }

        + .prediction.horz.horz {
            display: block;

            &:before {
                display: none;
            }
        }
    }
}

.prediction.prediction {
    display: inline;
    font-style: italic;
    font-size: 11px;

    &:before {
        content: "/";
        padding: 0 4px;
        display: inline-block;
        font-style: normal;
        font-size: 14px;
    }

    @media (max-width: 480px) {
        display: block;
        font-size: 10px;
        line-height: 1.6;
        margin-top: 2px;

        &:before {
            display: none;
        }
    }

    &.horz.horz {
        display: inline;
        border: none;

        &:before {
            display: inline;
        }
    }
}

.scoreBox {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0.75rem;

    @media (max-width: 480px) {
        margin-right: 0;
    }
}

.calculatorTippy {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    padding-left: 4px;
    padding-right: 4px;

    &.tippyTitle {
        padding-bottom: 4px;
        padding-left: 2px;
    }
}

.calculatorWrapper {
    max-width: 100% !important;
}

.tippyBox {
    display: flex;
    text-align: center;
    padding-bottom: 12px;
    font-size: 16px;
    white-space: nowrap;

    .small {
        display: block;
        font-size: 10px;
        text-align: left;
    }

    .smallSp {
        padding-left: 22px;
    }

    .smallFrS {
        padding-left: 4px;
    }

    .smallFr {
        padding-left: 6px;
    }

    .smallPr {
        padding-left: 16px;
    }

    .result {
        text-align: center;
        padding-left: 6px;
    }
}

.calculator {
    path {
        fill: var(--text-color);
    }
}

.isInfoAbs.isInfoAbs {
    position: absolute;
    top: -70%;
    left: 1rem;
    margin-left: 0;

    @media (max-width: 940px) {
      left: 0.4rem;
      top: -34%;
    }

    &.isRight {
        left: 3.5rem;

        @media (max-width: 940px) {
            left: 3.1rem;
        }
    }

    svg {
        width: 1.25rem;
        height: 1.25rem;
        fill: var(--white);
    }
}

.infoElem {

    > span {
        display: none;
    }
}

.scoresRow {
    background-color: rgb(var(--secondary-dark));

    @media (max-width: 480px) {
        display: none;
    }
}

.teamLogo {
    width: 24px;
    display: inline-block;
}
